import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap'
import styles from '../../styles/Home.module.scss';
import hero from '../../../../assets/images/hero.png';
import appStore from '../../../../assets/images/svg/appStore.svg';
import googlePlay from '../../../../assets/images/svg/googlePlay.svg';
import * as appConstants from '../../../../AppConstants';
import ym from 'react-yandex-metrika';

const log_download_ios = event => {
  ym(appConstants.YM_ID, 'reachGoal', appConstants.APPSTORE_GOAL_KEY);
}

class HomeContent extends Component {
  render() {
    const appStoreUrl = "https://redirect.appmetrica.yandex.com/serve/315958671794260160";
    return <Container>
      <Row className="align-items-top">
        <Col>
          <h1>
            Simple.<br/>
            Mobile.<br/>
            <span>Inventory.</span>
          </h1>
          <div className={styles.description}>
            Elevate business with inventory solutions—featuring powerful tools and flexible subscription options!
          </div>
          <div className={styles.buttons}>
            <a href={appStoreUrl} onClick={log_download_ios}><img src={appStore} /></a>
          </div>
        </Col>
        <Col md={7} className={styles.hero} data-pm data-pm-translatex>
          <img src={hero}/>
        </Col>
      </Row>
    </Container>
  }
}

export default HomeContent;